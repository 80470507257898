import React, { useState } from "react";
import "./HomePage.css";
import Layout from "../../components/layouts/Layout";
import SearchHome from "../../components/SearchHome/SearchHome";
import { InfoButton, ReprocessButton } from "../../components/Buttons/Buttons";
import DropdownHp from "../../components/Dropdowns/DropdownHp";
import TableHomePage from "../../components/Tables/TableHomePage";
import { FormGroup, Input } from "reactstrap";
import dayjs from "dayjs";
const HomePage = () => {
  //context
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [status, setStatus] = useState("Pending");
  const toggleStatus = () => setDropdownOpen((prevState) => !prevState);
  const [currentDate, setCurrentDate] = useState(dayjs().format("YYYY-MM-DD"));
  return (
    <Layout>
      <div className="homePage-top-menu">
        <SearchHome />
        <nav>
          <ul className="homepage-menu-ul">
            <li>
              <InfoButton name="Total Today: 1145 Files" />
            </li>
            <li>
              <FormGroup className="no-margin-bottom">
                <Input
                  type="date"
                  name="currentDate"
                  className="calender-btn"
                  id="currentDate"
                  value={currentDate}
                  onChange={(e) => setCurrentDate(e.target.value)}
                  placeholder="YYYY-MM-DD"
                />
              </FormGroup>
            </li>

            <li>
              <DropdownHp
                items={["Pending", "Approved"]}
                toggle={toggleStatus}
                isOpen={dropdownOpen}
                value={status}
                setItem={setStatus}
              />
            </li>
            <li>
              <ReprocessButton name="Reprocess" />
            </li>
          </ul>
        </nav>
      </div>
      <div>
        <TableHomePage />
      </div>
    </Layout>
  );
};

export default HomePage;
