import React, { useEffect, useState } from "react";
import dayjs from "dayjs";

const DateTimeDisplay = () => {
  const [currentTime, setCurrentTime] = useState(dayjs());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(dayjs());
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  const formattedDate = currentTime.format("DD-MM-YYYY");
  const formattedTime = currentTime.format("HH:mm:ss");

  return (
    <div>
      {formattedDate} | {formattedTime}
    </div>
  );
};

export default DateTimeDisplay;
