import React from "react";
import "./Buttons.css";
import NotificationsNoneRoundedIcon from "@mui/icons-material/NotificationsNoneRounded";
import CalendarMonthRoundedIcon from "@mui/icons-material/CalendarMonthRounded";
import dayjs from "dayjs";
import { Button } from "reactstrap";
import { Replay } from "@mui/icons-material";

export const NotifyButton = ({
  icon = <NotificationsNoneRoundedIcon />,
  notify,
}) => {
  return (
    <button className="add-edit-btn">
      <span>{icon}</span>
      {notify && <span className="btn-dot-top"></span>}
    </button>
  );
};

export const InfoButton = ({ name }) => {
  return (
    <button className="info-btn">
      <span>{name}</span>
    </button>
  );
};
export const CreateButton = ({ name, type }) => {
  return (
    <Button type={type} className="regis-create">
      <span>{name}</span>
    </Button>
  );
};
export const AsuraCreateButton = ({ name, type }) => {
  return (
    <Button type={type} className="regis-create-asura">
      <span>{name}</span>
    </Button>
  );
};
export const AsuraButton = ({ name, type, active }) => {
  return (
    <Button type={type} className={active ? "asura-btn active" : "asura-btn"}>
      <span>{name}</span>
    </Button>
  );
};

export const CalendarButton = ({
  date,
  icon = <CalendarMonthRoundedIcon />,
}) => {
  return (
    <button className="calender-btn">
      <span>{icon}</span>
      <span>{dayjs(date).format("DD-MM-YYYY")}</span>
    </button>
  );
};
export const IcoRightButton = ({ name, icon }) => {
  return (
    <button className="right-icon-btn">
      <span>{name}</span>
      <span>{icon}</span>
    </button>
  );
};
export const ReprocessButton = ({ name, icon = <Replay /> }) => {
  return (
    <button className="reprocess-btn">
      <span>{icon}</span>

      <span>{name}</span>
    </button>
  );
};
export const SwitchButton = ({ name }) => {
  return (
    <button className="switch-button">
      <span>{name}</span>
    </button>
  );
};
