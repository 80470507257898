import React, { useContext, useEffect } from "react";
import "./Header.css";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
//context
import { AppContext } from "../../contexts/AppContext";
import { NotifyButton } from "../Buttons/Buttons";
import ProfileImage from "../../assests/images/profile_img.png";
import Account from "../Account/Account";
const Header = () => {
  //context
  const { selectedLeftMenu, currentDate, setProfileInfo, profileInfo } =
    useContext(AppContext);

  useEffect(() => {
    const object = {
      name: "Aquib Yazdani",
      email: "aquib.yazdani@harnstech.com",
      src: ProfileImage,
    };
    setProfileInfo(object);
  }, [setProfileInfo]);
  return (
    <header className="header">
      <Breadcrumb item={selectedLeftMenu} newDate={currentDate} />
      <nav>
        <ul>
          <li>
            <NotifyButton notify={true} />
          </li>
          <li>
            <Account profileInfo={profileInfo} />
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;
