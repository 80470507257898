// src/utils/updateCSSVariables.js
export const updateCSSVariables = (collapseDdrawer) => {
  const root = document.documentElement;
  root.style.setProperty(
    "--left-drawer-width",
    collapseDdrawer ? "80px" : "248px"
  );
  root.style.setProperty(
    "--mirror-rotate-angle",
    collapseDdrawer ? "rotate(180deg)" : "rotate(0deg)"
  );
};
