import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";

const ConfirmationModal = ({ isOpen, toggle, handleMethod, id }) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle} size="sm" centered>
      <ModalHeader toggle={toggle}>Are you sure?</ModalHeader>
      <ModalBody>Do you really want to proceed?</ModalBody>
      <ModalFooter>
        <Button color="danger" onClick={toggle}>
          Cancel
        </Button>
        <Button
          color="success"
          onClick={() => {
            handleMethod("confirm", id);
          }}
        >
          Confirm
        </Button>
      </ModalFooter>
    </Modal>
  );
};
export default ConfirmationModal;
