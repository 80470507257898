import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { FormGroup, Input, Alert, Fade, Row, Col } from "reactstrap";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import * as Yup from "yup";
import {
  AsuraCreateButton,
  CreateButton,
} from "../../components/Buttons/Buttons";
import Layout from "../../components/layouts/Layout";
import { postData } from "../../utils/api";
import AlertMessage from "../../components/AlertMessage/AlertMessage";
import DoneIcon from "@mui/icons-material/Done";
import "./CreatePost.css";
// Validation schema using Yup
const validationSchema = Yup.object().shape({
  title: Yup.string().required("Title is required"),
  subtitle: Yup.string(),
  authors: Yup.string(),
  tags: Yup.string().required("Tags is required"),
});

// Component for displaying fade alert
const FadeAlert = ({ children }) => (
  <Fade className="fade-alert-input" in tag={Alert} color="danger">
    {children}
  </Fade>
);

// Function to generate URL from title
const generateUrlFromTitle = (title) => {
  if (!title) return "";
  return title.toLowerCase().replace(/\s+/g, "-");
};

// Component for creating posts
const CreatePost = () => {
  const [alert, setAlert] = useState(null); // State to manage alerts
  const [contents, setContents] = useState(""); // State to manage ReactQuill value
  const [isPostCreated, setIsPostCreated] = useState(false);
  const postCreation = async (values) => {
    const obj = {
      title: values?.title,
      shortTitle: values?.shortTitle,
      content: values?.content,
      author: values?.author, // This should be the ObjectId of the author (user)
      tags: values?.tags,
      url: values?.url,
    };

    try {
      await postData(
        "/api/posts",
        obj,
        (message) =>
          setAlert({
            type: "success",
            message: "Post is created successfully.",
          }), // Success callback
        (error) => setAlert({ type: "danger", message: error }) // Error callback
      );
      setIsPostCreated(true);
    } catch (error) {
      console.error("Failed to fetch data");
    }
  };
  const handleSubmit = (values) => {
    // Split the comma-separated string of tags into an array of strings
    const tagsArray = values.tags.split(",").map((tag) => tag.trim());

    // Create the post object with the updated tags array
    const postObject = {
      title: values.title,
      shortTitle: values.subtitle,
      content: values.content,
      author: values.author, // Ensure author field is properly passed
      tags: tagsArray, // Update tags with the array of strings
      url: generateUrlFromTitle(values.title),
    };

    postCreation(postObject);
    // You can perform actions like saving data to backend here
    console.log("Submitted values:", postObject);
  };

  return (
    <Layout>
      {alert && (
        <AlertMessage
          {...alert}
          title={"Credential"}
          onClose={() => setAlert(null)}
        />
      )}

      {!isPostCreated ? (
        <Formik
          initialValues={{
            title: "",
            subtitle: "",
            authors: "",
            content: "", // Initialize content as an empty string
            url: "", // Initialize url as an empty string
            tags: [],
            status: "",
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ errors, touched, values, setFieldValue }) => (
            <Form>
              <FormGroup>
                <Field
                  className="regis-input"
                  type="text"
                  name="title"
                  id="title"
                  placeholder="Title"
                  as={Input}
                  onChange={(e) => {
                    setFieldValue("title", e.target.value);
                    setFieldValue("url", generateUrlFromTitle(e.target.value));
                  }}
                />
                <ErrorMessage name="title" component={FadeAlert} />
              </FormGroup>
              <FormGroup>
                <Field
                  className="regis-input"
                  type="text"
                  name="subtitle"
                  id="subtitle"
                  placeholder="Subtitle"
                  as={Input}
                />
                <ErrorMessage name="subtitle" component={FadeAlert} />
              </FormGroup>
              <FormGroup>
                <Field
                  className="regis-input"
                  type="text"
                  name="author"
                  id="author"
                  placeholder="Author"
                  as={Input}
                />
                <ErrorMessage name="author" component={FadeAlert} />
              </FormGroup>
              <FormGroup>
                <Field
                  className="regis-input"
                  type="text"
                  name="url"
                  id="url"
                  placeholder="URL"
                  value={generateUrlFromTitle(values.title)}
                  readOnly
                  as={Input}
                />
              </FormGroup>
              <FormGroup>
                <Field
                  className="regis-input"
                  type="text"
                  name="tags"
                  id="tags"
                  placeholder="Tags (comma-separated)"
                  as={Input}
                />
                <ErrorMessage name="tags" component={FadeAlert} />
              </FormGroup>

              <FormGroup>
                <ReactQuill
                  theme="snow"
                  value={contents}
                  onChange={(content) => {
                    setContents(content);
                    setFieldValue("content", content); // Update content field in values
                  }}
                />
              </FormGroup>
              <div className="d-flex justify-content-between">
                <CreateButton name={"Create Post"} type="submit" />
              </div>
            </Form>
          )}
        </Formik>
      ) : (
        <Row className="created_post_done_row">
          <Col>
            <div className="done_created_post_wrapper">
              <DoneIcon />
            </div>
            <div>Your Post has been created</div>
            <Row onClick={() => window.open("/list-posts", "_self")}>
              <AsuraCreateButton name={"Back to List"} type="button" />
            </Row>
          </Col>
        </Row>
      )}
    </Layout>
  );
};

export default CreatePost;
