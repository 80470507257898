import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import {
  FormGroup,
  Input,
  Alert,
  Fade,
  Row,
  Col,
  InputGroup,
  InputGroupText,
} from "reactstrap";
import * as Yup from "yup";
import { postData } from "../../utils/api";
import AlertMessage from "../AlertMessage/AlertMessage";
import { Link } from "react-router-dom";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import { CreateButton } from "../Buttons/Buttons";
import Cookies from "js-cookie";

const validationSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Email is required"),
  password: Yup.string()
    .min(6, "Password must be at least 6 characters")
    .required("Password is required"),
});
const FadeAlert = ({ children }) => (
  <Fade className="fade-alert-input" in tag={Alert} color="danger">
    {children}
  </Fade>
);

const SignIn = () => {
  const [alert, setAlert] = useState(null); // State to manage alerts
  const [showPassword, setShowPassWord] = useState(false);

  const initialValues = {
    email: "",
    password: "",
  };
  const fetchData = async (data) => {
    try {
      await postData(
        "/api/auth/login",
        data,
        (message) =>
          setAlert({ type: "success", message: "Logged in successfully" }), // Success callback
        (error) =>
          setAlert({ type: "danger", message: "Invalid email or password" }) // Error callback
      );
      Cookies.set("cmsLogin", true, 30); //avoiding login cookie for now
      window.open("/", "_self");
    } catch (error) {
      console.error("Failed to fetch data");
    }
  };

  const handleSubmit = (values) => {
    fetchData(values);
    // You can perform further actions like API calls here
  };
  const toggleShowPassWord = () => {
    setShowPassWord((prevState) => !prevState);
  };
  return (
    <>
      {alert && (
        <AlertMessage
          {...alert}
          title={"Credential"}
          onClose={() => setAlert(null)}
        />
      )}{" "}
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ errors, touched }) => (
          <Form>
            <FormGroup>
              <Field
                className="regis-input"
                type="text"
                name="email"
                id="email"
                placeholder="Email"
                as={Input}
              />
              <ErrorMessage name="email" component={FadeAlert} />
            </FormGroup>
            <FormGroup>
              <InputGroup>
                <Field
                  className="regis-input"
                  type={showPassword ? "text" : "password"}
                  name="password"
                  id="password"
                  placeholder="Password"
                  as={Input}
                />

                <InputGroupText
                  onClick={() => toggleShowPassWord()}
                  className="regis-pass-eye"
                >
                  {!showPassword ? (
                    <VisibilityOutlinedIcon />
                  ) : (
                    <VisibilityOffOutlinedIcon />
                  )}
                </InputGroupText>
              </InputGroup>
              <ErrorMessage name="password" component={FadeAlert} />
            </FormGroup>

            <CreateButton name={"Sign in"} type="submit" />
          </Form>
        )}
      </Formik>
      <Row className="align-items-center mb-3 mt-2">
        <Col>
          <hr />
        </Col>
        <p className="regis-or">or</p>
        <Col>
          <hr />
        </Col>
      </Row>
      <Row className="align-items-center text-center">
        <p className="regis-info">
          Don't have an account? <Link to="/register">Register</Link>
        </p>
      </Row>
    </>
  );
};

export default SignIn;
