import React, { useContext, useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import {
  FormGroup,
  Input,
  Alert,
  Fade,
  ModalBody,
  Modal,
  Label,
  Row,
  Col,
  ModalHeader,
} from "reactstrap";
import "../forms/forms.css";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import * as Yup from "yup";
import { CreateButton } from "../../components/Buttons/Buttons";
import { patchData } from "../../utils/api";
import { AppContext } from "../../contexts/AppContext";

// Validation schema using Yup
const validationSchema = Yup.object().shape({
  title: Yup.string().required("Title is required"),
  subtitle: Yup.string(),
  author: Yup.string(),
});

// Component for displaying fade alert
const FadeAlert = ({ children }) => (
  <Fade className="fade-alert-input" in tag={Alert} color="danger">
    {children}
  </Fade>
);

// Function to generate URL from title
const generateUrlFromTitle = (title) => {
  if (!title) return "";
  return title.toLowerCase().replace(/\s+/g, "-");
};

function PostEditModal({ data, fetchData }) {
  const { isOpenPostEditrModal, toggleEditPostModal } = useContext(AppContext);

  const [alert, setAlert] = useState(null); // State to manage alerts
  const [contents, setContents] = useState(""); // State to manage ReactQuill value

  const postUpdate = async (obj) => {
    try {
      await patchData(
        `/api/posts/${data._id}`,
        obj,
        (message) =>
          setAlert({
            type: "success",
            message: "Post is created successfully.",
          }), // Success callback

        (error) => setAlert({ type: "danger", message: error }) // Error callback
      );
    } catch (error) {
      console.error("Failed to fetch data");
    }
  };

  const handleSubmit = (values) => {
    postUpdate(values);
    toggleEditPostModal();
    setTimeout(() => {
      fetchData();
    }, 1000);
  };

  useEffect(() => {
    if (data?.content.length) {
      setContents(data?.content);
    }
  }, []);
  return (
    <>
      <Modal
        size="xl"
        className="modal-post"
        isOpen={isOpenPostEditrModal}
        toggle={toggleEditPostModal}
        centered
      >
        <ModalBody className="p-0">
          <ModalHeader className="text-center pb-3 bg-dark text-white">
            <Col>Edit your post</Col>
          </ModalHeader>
          {data?.title?.length && (
            <Formik
              initialValues={{
                title: data.title,
                subtitle: data.shortTitle,
                author: data.author,
                content: data.content, // Initialize content as an empty string
                url: data.url, // Initialize url as an empty string
                tags: data.tags, // Initialize
                status: data.status,
              }}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ errors, touched, values, setFieldValue }) => (
                <Form className="p-4">
                  <Row>
                    <Col>
                      {" "}
                      <FormGroup>
                        <Label className="asura-label">Title</Label>
                        <Field
                          className="regis-input"
                          type="text"
                          name="title"
                          id="title"
                          placeholder="Title"
                          as={Input}
                          onChange={(e) => {
                            setFieldValue("title", e.target.value);
                            setFieldValue(
                              "url",
                              generateUrlFromTitle(e.target.value)
                            );
                          }}
                        />
                        <ErrorMessage name="title" component={FadeAlert} />
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label className="asura-label">Sub-title</Label>

                        <Field
                          className="regis-input"
                          type="text"
                          name="subtitle"
                          id="subtitle"
                          placeholder="Subtitle"
                          as={Input}
                        />
                        <ErrorMessage name="subtitle" component={FadeAlert} />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label className="asura-label">Author</Label>

                        <Field
                          className="regis-input"
                          type="text"
                          name="author"
                          id="author"
                          placeholder="Author"
                          as={Input}
                        />
                        <ErrorMessage name="author" component={FadeAlert} />
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label className="asura-label">Url</Label>

                        <Field
                          className="regis-input"
                          type="text"
                          name="url"
                          id="url"
                          placeholder="URL"
                          value={generateUrlFromTitle(values.title)}
                          readOnly
                          as={Input}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label className="asura-label">Tags</Label>

                        <Field
                          className="regis-input"
                          type="text"
                          name="tags"
                          id="tags"
                          placeholder="Tags (comma-separated)"
                          as={Input}
                        />
                        <ErrorMessage name="tags" component={FadeAlert} />
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label className="asura-label">
                          Status (Will be approved by admin)
                        </Label>

                        <Field
                          className="regis-input"
                          type="text"
                          name="status"
                          id="status"
                          placeholder="Author"
                          as={Input}
                          readOnly
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <FormGroup>
                    <ReactQuill
                      theme="snow"
                      value={contents}
                      onChange={(content) => {
                        setContents(content);
                        setFieldValue("content", content); // Update content field in values
                      }}
                    />
                  </FormGroup>
                  <div className="d-flex justify-content-between">
                    <CreateButton name={"Update Post"} type="submit" />
                  </div>
                </Form>
              )}
            </Formik>
          )}
        </ModalBody>
      </Modal>
    </>
  );
}

export default PostEditModal;
