import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import {
  FormGroup,
  Input,
  Alert,
  Fade,
  Row,
  Col,
  InputGroup,
} from "reactstrap";
import * as Yup from "yup";
import { postData } from "../../utils/api";
import AlertMessage from "../AlertMessage/AlertMessage";
import { CreateButton } from "../Buttons/Buttons";

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
  mobileNumber: Yup.string()
    .matches(/^\d{10}$/, "Invalid mobile number")
    .required("Mobile number is required"),
  password: Yup.string()
    .min(8, "Password must be at least 8 characters")
    .required("Password is required"),
});

const FadeAlert = ({ children }) => (
  <Fade className="fade-alert-input" in tag={Alert} color="danger">
    {children}
  </Fade>
);

const SignUp = () => {
  const [alert, setAlert] = useState(null); // State to manage alerts

  const initialValues = {
    name: "",
    email: "",
    mobileNumber: "",
    password: "",
  };

  const fetchData = async (data) => {
    try {
      await postData(
        `/api/auth/register`,
        data,
        (message) => setAlert({ type: "success", message }), // Success callback
        (error) => setAlert({ type: "danger", message: error }) // Error callback
      );
    } catch (error) {
      console.error("Failed to submit data", error);
      setAlert({ type: "danger", message: "Failed to submit data" });
    }
  };

  const handleSubmit = (values) => {
    const data = {
      name: values.name,
      email: values.email,
      mobile: values.mobileNumber,
      password: values.password,
    };
    fetchData(data);
  };

  return (
    <>
      {alert && (
        <AlertMessage
          title={"Credential"}
          {...alert}
          onClose={() => setAlert(null)}
        />
      )}
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ errors, touched }) => (
          <Form>
            <FormGroup>
              <Field
                className="regis-input"
                type="text"
                name="name"
                id="name"
                placeholder="Name"
                as={Input}
              />
              <ErrorMessage name="name" component={FadeAlert} />
            </FormGroup>

            <FormGroup>
              <InputGroup>
                <Field
                  className="regis-input"
                  type="email"
                  name="email"
                  id="email"
                  placeholder="Email"
                  as={Input}
                />
              </InputGroup>
              <ErrorMessage name="email" component={FadeAlert} />
            </FormGroup>

            <FormGroup>
              <InputGroup>
                <Field
                  className="regis-input"
                  type="text"
                  name="mobileNumber"
                  id="mobileNumber"
                  placeholder="Mobile Number"
                  as={Input}
                />
              </InputGroup>
              <ErrorMessage name="mobileNumber" component={FadeAlert} />
            </FormGroup>

            <FormGroup>
              <Field
                className="regis-input"
                type="password"
                name="password"
                id="password"
                placeholder="Password"
                as={Input}
              />
              <ErrorMessage name="password" component={FadeAlert} />
            </FormGroup>

            <CreateButton name={"Create New Account"} type="submit" />
          </Form>
        )}
      </Formik>
      <Row className="align-items-center mb-3 mt-2">
        <Col>
          <hr />
        </Col>
        <p className="regis-or">or</p>
        <Col>
          <hr />
        </Col>
      </Row>
      <Row className="align-items-center text-center">
        <p className="regis-info">
          Already have an account? <a href="/login">Sign in</a>
        </p>
      </Row>
    </>
  );
};

export default SignUp;
