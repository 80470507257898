// src/contexts/AppContext.js
import React, { createContext, useEffect, useState } from "react";
import { updateCSSVariables } from "../utils/cssVariables";

// Create the context with a default value
export const AppContext = createContext();

// Create a provider component
export const AppContextProvider = ({ children }) => {
  const [theme, setTheme] = useState("light"); // Default to light theme
  //main left menu state
  const [selectedLeftMenu, setSelectedLeftMenu] = useState("Home");
  //for date time state
  const [currentDate, setCurrentDate] = useState(new Date());
  //usertype
  const [userType, setUserType] = useState("admin");

  const [isOpenPostEditrModal, setIsOpenPostEditModal] = useState(false);
  const [postData, setPostData] = useState(); //single open post data

  const [profileInfo, setProfileInfo] = useState({
    name: "",
    email: "",
    src: "",
  });

  //collapse left drawer
  const [collapseLeftDrawer, setCollapseLeftDrawer] = useState(true);
  const toggleCollpaseDrawer = () => {
    setCollapseLeftDrawer(!collapseLeftDrawer);
  };

  const toggleEditPostModal = () => {
    setIsOpenPostEditModal(!isOpenPostEditrModal);
    setPostData({});
  };

  const toggleTheme = () => {
    setTheme((prevTheme) => (prevTheme === "light" ? "dark" : "light"));
  };

  useEffect(() => {
    updateCSSVariables(collapseLeftDrawer);
  }, [collapseLeftDrawer]);
  return (
    <AppContext.Provider
      value={{
        theme,
        toggleTheme,
        selectedLeftMenu,
        setSelectedLeftMenu,
        currentDate,
        setCurrentDate,
        profileInfo,
        setProfileInfo,
        isOpenPostEditrModal,
        setIsOpenPostEditModal,
        toggleEditPostModal,
        postData,
        setPostData,
        toggleCollpaseDrawer,
        collapseLeftDrawer,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
