import React, { useState } from "react";
import { Table, Input } from "reactstrap";
import "./TableHomepage.css"; // Adjust the path as necessary
import PaginationHome from "../Paginations/PaginationHome";

const TableHomePage = () => {
  const [pageNumber, setPageNumber] = useState(1);
  // Mock data
  const data = Array.from({ length: 20 }, (_, i) => ({
    id: i + 1,
    name: `Add_dummy${i + 1}`,
    time: "15:12:35 Sat 23 May 2024",
    agency: `Agency ${i + 1}`,
    roNo: i % 2 === 0 ? "Sandeep" : "Ashish",
    status:
      i % 4 === 0
        ? "Waiting"
        : i % 4 === 1
        ? "Approved"
        : i % 4 === 2
        ? "Asura Delay"
        : "Rejected",
  }));

  const statusColors = {
    Waiting: "warning",
    Approved: "success",
    "Asura Delay": "info",
    Rejected: "danger",
  };

  return (
    <div className="container-fluid bg-white px-0">
      <Table bordered hover responsive>
        <thead>
          <tr className="home-table-header">
            <th>
              <Input type="checkbox" />
            </th>
            <th>Advertise</th>
            <th>Upload Time</th>
            <th>Agency</th>
            <th>RO No.</th>
            <th>Status</th>
            <th>Logs</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item) => (
            <tr key={item.id} className="home-table-body">
              <td>
                <Input type="checkbox" />
              </td>
              <td>{item.name}</td>
              <td className="text-muted">{item.time}</td>
              <td className="text-muted">{item.agency}</td>
              <td className="text-muted">{item.roNo}</td>
              <td>
                <span className={`badge bg-${statusColors[item.status]}`}>
                  <span className="badge-dot-wrapper"></span> {item.status}
                </span>
              </td>
              <td>
                <a href="/">View Log</a>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <div className="d-flex justify-content-center align-items-center py-3 gap-3">
        <PaginationHome setPageNumber={setPageNumber} pageNumber={pageNumber} />
      </div>
    </div>
  );
};

export default TableHomePage;
