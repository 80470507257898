import React, { useEffect, useState } from "react";
import Layout from "../../components/layouts/Layout";
import "./PreviewPost.css";
import { getData } from "../../utils/api";
import { useParams } from "react-router-dom";
import { Row } from "reactstrap";

export const PostPreview = () => {
  const [post, setPost] = useState(null);
  const { postUrl } = useParams(); // Accessing route parameters
  const [loading, setLoading] = useState(true);

  const fetchData = async () => {
    setLoading(true);
    const path = `/api/posts`;
    try {
      const response = await getData(
        path,
        (message) => console.log("message"), // Success callback
        (error) => console.log(error) // Error callback
      );
      const foundPost = response.find((p) => p.url === postUrl);
      if (foundPost) {
        setPost(foundPost);
      }
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    } catch (error) {
      setLoading(false);
      console.error("Failed to fetch data");
    }
  };

  useEffect(() => {
    fetchData();
  }, [postUrl]);

  if (!post) {
    return <div>Loading...</div>;
  }
  return (
    <Layout>
      <div className="post-preview-wrapper">
        <h2 className="text-center">{post.title}</h2>
        <h4 className="text-center text-muted">{post.shortTitle}</h4>
        {post.author?.length && (
          <p className="text-center text-muted">By: {post.author}</p>
        )}
        <div dangerouslySetInnerHTML={{ __html: post.content }} />

        <Row className="p-3 bg-light border-2 justify-content-center align-items-center">
          <p className="mb-2 text-center">Likes: {post.likes}</p>
          Comments:{" "}
          {post.comments?.length > 0
            ? post.comments.map((comment) => {
                return <p>{comment}</p>;
              })
            : 0}
        </Row>
      </div>
    </Layout>
  );
};
