import { Col, Row } from "reactstrap";
import "./registration.css";
import logo_login from "../../assests/images/main_logo.png";
import ImageSlider from "../../components/Slider/ImageSlider";
import image_1 from "../../assests/images/slider_1.png";
import image_2 from "../../assests/images/slider_2.png";
import image_3 from "../../assests/images/slider_3.png";
import image_4 from "../../assests/images/slider_4.png";
import SignIn from "../../components/auth/SignIn";

function Login() {
  const sliderImages = [image_2, image_1, image_3, image_4];

  return (
    <Row className="regis-form-wrapper">
      <Col md="6" className="regis-left-wrapper px-4">
        <div className="regis-logo-wrapper">
          <img width={150} src={logo_login} alt="logo" />
        </div>
        <h3 className="regis-heading">
          Make your life <br /> Easier
        </h3>

        <SignIn />
      </Col>
      <Col md="6" className="regis-right-wrapper px-4">
        <ImageSlider images={sliderImages} duration={5000} />
      </Col>
    </Row>
  );
}

export default Login;
