import { Col, Row } from "reactstrap";
import "./Account.css";

function Account({ profileInfo }) {
  return (
    <Row className="profile-wrapper">
      <Col className="profile-img-wrapper">
        <img src={profileInfo?.src} alt="my-account" />
      </Col>
      <Col className="profile-info-wrapper">
        <div className="profile-info-name">{profileInfo?.name}</div>
        <div className="profile-info-email">{profileInfo?.email}</div>
      </Col>
    </Row>
  );
}

export default Account;
