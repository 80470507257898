import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import "./DropdownHp.css";
function DropdownHp({ isOpen, toggle, items, value, setItem }) {
  return (
    <Dropdown isOpen={isOpen} toggle={toggle}>
      <DropdownToggle caret className="dropdwn-btn-home">
        {value}
      </DropdownToggle>
      <DropdownMenu className="dropdwn-home-menu">
        {items?.map((item) => {
          return (
            <DropdownItem key={item} onClick={() => setItem(item)}>
              {item}
            </DropdownItem>
          );
        })}
      </DropdownMenu>
    </Dropdown>
  );
}

export default DropdownHp;
