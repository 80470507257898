import React, { useContext, useEffect } from "react";
import "./LeftDrawer.css";
import { Col, Row } from "reactstrap";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import ViewListIcon from "@mui/icons-material/ViewList";
import AssessmentIcon from "@mui/icons-material/Assessment";
import Chat from "../Chat/Chat";
//context
import { AppContext } from "../../contexts/AppContext";
import { Link, useLocation } from "react-router-dom";
import { Create } from "@mui/icons-material";
import PagesRoundedIcon from "@mui/icons-material/PagesRounded";
import logo from "../../assests/images/logo_login.png";
function LeftDrawer() {
  //context
  const { selectedLeftMenu, setSelectedLeftMenu, collapseLeftDrawer } =
    useContext(AppContext);

  const location = useLocation();

  const leftMenu = [
    {
      name: "Home",
      icon: <HomeRoundedIcon />,
      link: "/",
    },
    {
      name: "Create Post",
      icon: <Create />,
      link: "/create-post",
    },
    {
      name: "List Posts",
      icon: <ViewListIcon />,
      link: "/list-posts",
    },
    {
      name: "Posts Status",
      icon: <PagesRoundedIcon />,
      link: "/post-status",
    },
    {
      name: "Reports",
      icon: <AssessmentIcon />,
      link: "/reports",
    },
  ];
  useEffect(() => {
    const currentPath = location.pathname;
    const currentItem = leftMenu.find((item) => item.link === currentPath);
    if (currentItem) {
      setSelectedLeftMenu(currentItem.name);
    }
  }, [location.pathname]);
  return (
    <div className={"lft_drwr_wrppr"}>
      <Row className="logo_wrapper">
        {!collapseLeftDrawer ? (
          <Row>
            <img src={logo} alt="logo" />
            aquib-cms
          </Row>
        ) : (
          <img src={logo} alt="logo" />
        )}
      </Row>
      <Row className="mt-4">
        <Col>
          {leftMenu &&
            leftMenu?.map((menu) => {
              return (
                <Link
                  to={menu?.link}
                  key={menu.name}
                  className={
                    selectedLeftMenu === menu.name
                      ? "left_menu_wrapper active"
                      : "left_menu_wrapper"
                  }
                  onClick={() => setSelectedLeftMenu(menu.name)}
                >
                  <span
                    className={
                      selectedLeftMenu === menu.name
                        ? `left_menu_ico active`
                        : `left_menu_ico`
                    }
                  >
                    {menu.icon}
                  </span>
                  {!collapseLeftDrawer ? menu.name : ""}
                </Link>
              );
            })}
        </Col>
      </Row>
      {!collapseLeftDrawer && <Chat />}
    </div>
  );
}

export default LeftDrawer;
