import { ChevronLeft, ChevronRight } from "react-feather";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";
import "./PaginationHome.css";
function PaginationHome({ pageNumber, setPageNumber }) {
  return (
    <div className="pagination-container">
      <Pagination aria-label="Page navigation example">
        <PaginationItem
          className={
            pageNumber > 1 ? "arrow-pg-wrapper" : "arrow-pg-wrapper disabled"
          }
        >
          <ChevronLeft
            onClick={() => {
              pageNumber > 1 && setPageNumber(pageNumber - 1);
            }}
          />
        </PaginationItem>
        {[...Array(5)].map((_, index) => (
          <PaginationItem key={index} active={index === pageNumber - 1}>
            <PaginationLink
              onClick={() => {
                setPageNumber(index + 1);
              }}
            >
              {index + 1}
            </PaginationLink>
          </PaginationItem>
        ))}

        <PaginationItem
          className={pageNumber > 1 ? "arrow-pg-wrapper" : "arrow-pg-wrapper"}
        >
          <ChevronRight
            onClick={() => {
              setPageNumber(pageNumber + 1);
            }}
          />
        </PaginationItem>
      </Pagination>
    </div>
  );
}

export default PaginationHome;
