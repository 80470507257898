import { Col, FormGroup, Input, Row } from "reactstrap";
import chatLogo from "../../assests/images/chat-logo.svg";
import "./Chat.css";
import { Send } from "@mui/icons-material";
function Chat() {
  return (
    <Row>
      <Col className="chat-wrapper">
        <div className="chat-logo-wrapper">
          <img src={chatLogo} alt="chat logo" />
        </div>
        <FormGroup>
          <Input className="chat-input" placeholder="Start a Chat" />
          <span className="chat-btn">
            <Send fontSize="24px" />
          </span>
        </FormGroup>
      </Col>
    </Row>
  );
}

export default Chat;
