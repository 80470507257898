import React, { useState, useEffect } from "react";
import { Alert } from "reactstrap"; // Reactstrap Alert component
import "../../pages/Asura/AsuraSetup.css";
const AlertMessage = ({
  type,
  message,
  duration = 100000000,
  onClose,
  className,
  title,
  titleClassName,
}) => {
  const [visible, setVisible] = useState(true);

  // Auto-close the alert after a specified duration
  useEffect(() => {
    const timer = setTimeout(() => {
      setVisible(false);
      if (onClose) onClose(); // Call onClose callback if provided
    }, duration);

    return () => clearTimeout(timer); // Cleanup timer on component unmount
  }, [duration, onClose]);

  return (
    <Alert
      className={className ?? "alert_asura"}
      color={type}
      isOpen={visible}
      toggle={() => setVisible(false)}
    >
      <div className={titleClassName}>{title}</div>
      {message}
    </Alert>
  );
};

export default AlertMessage;
