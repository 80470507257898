import React, { useState, useEffect } from "react";
import "./Slider.css";

const ImageSlider = ({ images, duration }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const nextSlide = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  const prevSlide = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const selectSlide = (index) => {
    setCurrentImageIndex(index);
  };
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) =>
        prevIndex === images.length - 1 ? 0 : prevIndex + 1
      );
    }, duration);

    return () => clearInterval(interval);
  }, [images]);
  return (
    <div className="slider-container">
      <div className="shadow-add"></div>
      <div className="slider">
        {images.map((image, index) => (
          <div
            key={index}
            className={index === currentImageIndex ? "slide active" : "slide"}
            style={{
              backgroundImage: `url(${image})`,
              transform: `translateX(-${currentImageIndex * 100}%)`,
            }}
          ></div>
        ))}
      </div>
      <div className="dots-slider">
        {images.map((_, index) => (
          <span
            key={index}
            className={index === currentImageIndex ? "dot active" : "dot"}
            onClick={() => selectSlide(index)}
          ></span>
        ))}
      </div>
    </div>
  );
};

export default ImageSlider;
