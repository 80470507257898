import React, { useState } from "react";
import { FormGroup, Input, InputGroup, InputGroupText } from "reactstrap";

import "./Searchhome.css";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import "../../App.css";
const SearchHome = ({
  placeholder = "Search for any file, agency, etc",
  onSearch,
}) => {
  const [searchQuery, setSearchQuery] = useState("");

  const handleChange = (e) => {
    setSearchQuery(e.target.value);
    if (onSearch) {
      onSearch(e.target.value);
    }
  };

  return (
    <FormGroup className="no-margin-bottom">
      <InputGroup>
        <InputGroupText className="home-search-eye">
          <SearchRoundedIcon />
        </InputGroupText>
        <Input
          className="search-input-home"
          type="text"
          placeholder={placeholder}
          value={searchQuery}
          onChange={handleChange}
        />
      </InputGroup>
    </FormGroup>
  );
};

export default SearchHome;
