import React, { useEffect, useState } from "react";
import "../Home/HomePage.css";
import Layout from "../../components/layouts/Layout";
import SearchHome from "../../components/SearchHome/SearchHome";
import { InfoButton, ReprocessButton } from "../../components/Buttons/Buttons";
import DropdownHp from "../../components/Dropdowns/DropdownHp";
import { FormGroup, Input } from "reactstrap";
import dayjs from "dayjs";
import { getData } from "../../utils/api";
import PostStatusTable from "../../components/Tables/PostStatusTable";
const PostsStatus = () => {
  //context
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [status, setStatus] = useState("All");
  const toggleStatus = () => setDropdownOpen((prevState) => !prevState);
  const [currentDate, setCurrentDate] = useState(dayjs().format("YYYY-MM-DD"));
  const [allPosts, setAllPosts] = useState();
  const [loading, setLoading] = useState(true);

  const fetchData = async (status) => {
    setLoading(true);
    const path = status ? `/api/posts?status=${status}` : `/api/posts`;
    try {
      const response = await getData(
        path,
        (message) => console.log("message"), // Success callback
        (error) => console.log(error) // Error callback
      );
      setAllPosts(response);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    } catch (error) {
      setLoading(false);
      console.error("Failed to fetch data");
    }
  };

  const handleSubmit = () => {
    fetchData();
    // You can perform further actions like API calls here
  };
  //filter by status
  const filterByStatus = (status) => {
    if (status === "All") {
      handleSubmit();
    } else fetchData(status.toLowerCase());
    // You can perform further actions like API calls here
  };

  useEffect(() => {
    handleSubmit();
  }, []);

  return (
    <Layout>
      <div className="homePage-top-menu">
        <SearchHome />
        <nav>
          <ul className="homepage-menu-ul">
            <li>
              <InfoButton
                name={`Total Today: ${
                  allPosts?.length ? allPosts?.length : 0
                } Files`}
              />
            </li>
            <li>
              <FormGroup className="no-margin-bottom">
                <Input
                  type="date"
                  name="currentDate"
                  className="calender-btn"
                  id="currentDate"
                  value={currentDate}
                  onChange={(e) => setCurrentDate(e.target.value)}
                  placeholder="YYYY-MM-DD"
                />
              </FormGroup>
            </li>

            <li>
              <DropdownHp
                items={["All", "Pending", "Approved"]}
                toggle={toggleStatus}
                isOpen={dropdownOpen}
                placeholder="Status"
                value={status}
                setItem={(newStatus) => {
                  setStatus(newStatus);
                  filterByStatus(newStatus);
                }}
              />
            </li>
            <li onClick={handleSubmit}>
              <ReprocessButton name="Refresh" />
            </li>
          </ul>
        </nav>
      </div>
      <div>
        {allPosts?.length && (
          <PostStatusTable
            loading={loading}
            setLoading={setLoading}
            data={allPosts}
            fetchData={fetchData}
          />
        )}
      </div>
    </Layout>
  );
};

export default PostsStatus;
