import React, { useContext } from "react";
import "./Breadcrumb.css";
import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import DateTimeDisplay from "../DateTimeDisplay/DateTimeDisplay";
import { AppContext } from "../../contexts/AppContext";

const Breadcrumb = ({ item, newDate }) => {
  const { toggleCollpaseDrawer } = useContext(AppContext);

  return (
    <nav aria-label="breadcrumb">
      <ol className="breadcrumb m-0">
        <li className={`breadcrumb-item-back`}>
          <ArrowBackIosNewRoundedIcon
            onClick={toggleCollpaseDrawer}
            fontSize="small"
          />
        </li>
        <li className="breadcrumb-item">{item}</li>
        <li className="breadcrumb-item-date">
          <DateTimeDisplay newDate={newDate} />
        </li>
      </ol>
    </nav>
  );
};

export default Breadcrumb;
