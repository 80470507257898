import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Cookies from "js-cookie";
import { AppContextProvider } from "./contexts/AppContext";
import Header from "./components/Header/Header";
import HomePage from "./pages/Home/HomePage";
import AboutPage from "./pages/About/AboutPage";
import LeftDrawer from "./components/LeftDrawer/LeftDrawer";
import Registration from "./pages/authentication/Registration";
import TestApi from "./pages/TestApiPage/TestApi";
import Login from "./pages/authentication/Login";
import CreatePost from "./pages/CreatePost/CreatePost";
import PostsList from "./pages/PostsList/PostsList";
import PostsStatus from "./pages/PostStatus/PostsStatus";
import { PostPreview } from "./pages/PreviewPost/PostPreview";

const ProtectedRoute = ({ element, ...rest }) => {
  if (Cookies.get("cmsLogin")) {
    return element;
  } else {
    return <Navigate to="/login" />;
  }
};

const App = () => {
  return (
    <AppContextProvider>
      <Router>
        <Routes>
          <Route path="/register" element={<Registration />} />
          <Route path="/login" element={<Login />} />

          {/* Protected Routes */}
          <Route
            path="/"
            element={
              <>
                <Header />
                <LeftDrawer />
                <ProtectedRoute element={<HomePage />} />
              </>
            }
          />

          <Route
            path="/list-posts"
            element={
              <>
                <Header />
                <LeftDrawer />
                <ProtectedRoute element={<PostsList />} />
              </>
            }
          />
          <Route
            path="/post-status"
            element={
              <>
                <Header />
                <LeftDrawer />
                <ProtectedRoute element={<PostsStatus />} />
              </>
            }
          />
          <Route
            path="/create-post"
            element={
              <>
                <Header />
                <LeftDrawer />
                <ProtectedRoute element={<CreatePost />} />
              </>
            }
          />

          <Route
            path="/reports"
            element={
              <>
                <Header />
                <LeftDrawer />
                <ProtectedRoute element={<HomePage />} />
              </>
            }
          />
          <Route
            path="/about"
            element={
              <>
                <Header />
                <LeftDrawer />
                <ProtectedRoute element={<AboutPage />} />
              </>
            }
          />
          <Route
            path="/test"
            element={
              <>
                <Header />
                <LeftDrawer />
                <ProtectedRoute element={<TestApi />} />
              </>
            }
          />
          <Route
            path="/post/:postUrl"
            element={
              <>
                <Header />
                <LeftDrawer />
                <ProtectedRoute element={<PostPreview />} />
              </>
            }
          />
        </Routes>
      </Router>
    </AppContextProvider>
  );
};

export default App;
