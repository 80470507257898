import React, { useContext, useState } from "react";
import { Table, Input } from "reactstrap";
import "./TableHomepage.css"; // Adjust the path as necessary
import Loader from "../Loader/Loader";
import { formatTimestamp } from "../../utils/functions";
import { AppContext } from "../../contexts/AppContext";
import { deleteData, patchData } from "../../utils/api";
import PostEditModal from "../Modals/PostEditModal";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import ConfirmationModal from "../Modals/ConfirmationModal";
import { Link } from "react-router-dom";

const PostStatusTable = ({ data, loading, fetchData }) => {
  const { postData, setPostData } = useContext(AppContext);
  const [alert, setAlert] = useState(null); // State to manage alerts

  // const [pageNumber, setPageNumber] = useState(1);
  const [modalConfirm, setModalConfirm] = useState(false);
  const [deletePostId, setDeletePostId] = useState("");
  const toggleConfirmModal = () => setModalConfirm(!modalConfirm);

  const statusColors = {
    pending: "warning",
    darft: "info",
    published: "success",
    approved: "success",
    "Asura Delay": "info",
    Rejected: "danger",
  };

  const deletePost = async (id) => {
    try {
      const response = await deleteData(
        `/api/posts/${id}`,
        (message) => console.log(message),
        (error) => console.log(error) // Error callback
      );
      setPostData(response);
    } catch (error) {
      console.error("Failed to delete data");
    }
  };

  const handleDeletePost = (step, id) => {
    if (step === "confirm") {
      deletePost(id);
      toggleConfirmModal();
      fetchData();
    } else {
      setDeletePostId(id);
    }
  };
  const handlePostStatus = async (id, status) => {
    try {
      await patchData(
        `/api/posts/${id}/status`,
        { status: status ? "approved" : "pending" },
        (message) => fetchData(),
        setAlert({
          type: "success",
          message: "Post is created successfully.",
        }), // Success callback
        (error) => setAlert({ type: "danger", message: error }) // Error callback
      );
    } catch (error) {
      console.error("Failed to fetch data");
    }
  };
  // Function to toggle site admin and pdf download
  const handleToggle = (id, status) => {
    handlePostStatus(id, status);
    setAlert({ type: "success", message: "Role updated successfully" });
  };
  return (
    <div className="container-fluid bg-white px-0">
      {loading ? (
        <Loader open={loading} />
      ) : (
        <Table bordered hover responsive>
          <thead>
            <tr className="home-table-header">
              <th>
                <Input type="checkbox" />
              </th>

              <th>S No.</th>
              <th>Title</th>
              <th>Created At</th>
              <th>Author</th>
              <th>Status</th>
              <th>Approve</th>
              <th>View</th>
              <th>Delete</th>
            </tr>
          </thead>
          <tbody>
            {data?.length &&
              data?.map((item, i) => (
                <tr key={item.createdAt} className="home-table-body">
                  <td>
                    <Input type="checkbox" />
                  </td>
                  <td>{i + 1}</td>
                  <td>{item.title}</td>
                  <td className="text-muted">
                    {formatTimestamp(item.createdAt)}
                  </td>
                  <td className="text-muted">{item.author}</td>
                  <td>
                    <span className={`badge bg-${statusColors[item.status]}`}>
                      <span className="badge-dot-wrapper"></span> {item.status}
                    </span>
                  </td>
                  <td className="text-muted">
                    <label className="switch">
                      <input
                        type="checkbox"
                        checked={item.status === "approved" ? true : false}
                        onChange={(e) => {
                          handleToggle(item._id, e.target.checked);
                        }}
                      />
                      <span className="slider"></span>
                    </label>
                  </td>

                  <td>
                    <Link to={`/post/${item.url}`}>View Post</Link>
                  </td>
                  <td>
                    <DeleteOutlineOutlinedIcon
                      className="cursor-pointer"
                      onClick={() => {
                        toggleConfirmModal();
                        handleDeletePost("firstStep", item._id);
                      }}
                    />
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      )}
      <PostEditModal data={postData} fetchData={fetchData} />
      {/* 
      <div className="d-flex justify-content-center align-items-center py-3 gap-3">
        <PaginationHome setPageNumber={setPageNumber} pageNumber={pageNumber} />
      </div> */}
      <ConfirmationModal
        isOpen={modalConfirm}
        toggle={toggleConfirmModal}
        id={deletePostId}
        handleMethod={handleDeletePost}
      />
    </div>
  );
};

export default PostStatusTable;
